<script>
import store from "@/store/index.js";

export default {
    name: "App",
    created() {
        this.init();
    },
    methods: {
        init() {
            const themeLink = document.querySelector("body");
            if (!window.localStorage.theme)
                window.localStorage.theme = "sdmslight";
            if (!localStorage.imgbackgroud) {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                const imgbackground = `rgba(${r}, ${g}, ${b},0.5)`;
                localStorage.setItem("imgbackground", imgbackground);
            }
            themeLink.setAttribute("class", window.localStorage.theme);
            themeLink.setAttribute("gt-theme", window.localStorage.theme);
            this.$addStorageEvent(1, "theme", window.localStorage.theme);

            store.dispatch("autoSetDeviceType");

            const navLang = navigator.language;
            const localLang = navLang === "zh-CN" || navLang === "en-US" ? navLang : false;
            const language = window.localStorage.getItem("language") || localLang || "zh-CN";

            if (language === "zh-CN") {
                this.$i18n.locale = "zh-CN";
                store.commit("user/setLanguage", "zh-CN");
            }
            else if (language === "zh-TW" || language === "zh-HK" || language === "zh-MO" || language === "zh-Hant" || language === "zh-SG") {
                this.$i18n.locale = "zh-TW";
                store.commit("user/setLanguage", "zh-TW");
            }
            else {
                this.$i18n.locale = "en-US";
                store.commit("user/setLanguage", "en-US");
            }
        },
    },
};
</script>

<template>
    <router-view />
</template>

<style scoped>

</style>
