<script setup>
import { computed } from "vue";
import Pinyin from "js-pinyin";
import store from "@/store/index.js";
import useLanguage from "@/hooks/useLanguage.js";
import router from "@/router/index.js";
import useAuth from "@/hooks/useAuth.js";

const emit = defineEmits(["toAccount"]);

const userInfo = computed(() => {
    return store.state.user.userInfo;
});

const avatarBackgroundColorMap = {
    A: "#135393",
    B: "#348133",
    C: "#533211",
    D: "#813454",
    E: "#807032",
    F: "#553483",
    G: "#337492",
    H: "#352333",
    I: "#941423",
    J: "#327f51",
    K: "#113291",
    L: "#932b12",
    M: "#747354",
    N: "#147596",
    O: "#455437",
    P: "#924162",
    Q: "#42425e",
    R: "#925013",
    S: "#7f6031",
    T: "#623262",
    U: "#19388a",
    V: "#802b16",
    W: "#615289",
    X: "#122a1a",
    Y: "#336212",
    Z: "#124411",
};

const usernameFirstLetter = computed(() => {
    if (!userInfo.value?.username)
        return "A";
    return userInfo.value.username.charAt(0).toUpperCase();
});

const avatarBgColor = computed(() => {
    const a = usernameFirstLetter.value;
    const aa = Pinyin.getFullChars(a);
    const a1 = aa.charAt(0);
    return avatarBackgroundColorMap[a1];
});

const { changeLanguage } = useLanguage();

function goToHubPage() {
    router.push({ name: "hub" });
}

const { logout } = useAuth();

function toSDK(url) {
    window.open(
        url,
        "_blank",
    );
}

function handleDropdownClick(name) {
    switch (name) {
        case "setting":
        {
            emit("to-account");
        }
    }
}
function toSetting(){
    console.log('1233')
    emit("to-account");
}
</script>

<template>
    <Dropdown trigger="hover" style="user-select: none; cursor: pointer" @on-click="handleDropdownClick">
        <div style="display: flex;flex-direction: row;align-items: center;color: white">
            <Avatar v-if="userInfo?.photo" :src="userInfo.photo" />
            <Avatar v-else :style="{ backgroundColor: avatarBgColor }">
                {{ usernameFirstLetter }}
            </Avatar>
            <span style="margin: 0 4px;">
                {{ userInfo?.username }}
            </span>
            <i class="fal fa-angle-down" />
        </div>

        <template #list>
            <DropdownMenu>
                <Dropdown placement="left-start">
                    <DropdownItem>
                        <i class="fal fa-globe" />
                        {{ $t("x_lang") }}
                        <i class="fal fa-angle-right" />
                    </DropdownItem>
                    <template #list>
                        <DropdownMenu>
                            <DropdownItem>
                                <span @click="changeLanguage('en-US')">
                                    English
                                </span>
                            </DropdownItem>
                            <DropdownItem>
                                <span @click="changeLanguage('zh-TW')">
                                    繁體中文
                                </span>
                            </DropdownItem>
                            <DropdownItem>
                                <span @click="changeLanguage('zh-CN')">
                                    简体中文
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </template>
                </Dropdown>

                <DropdownItem name="setting">
                   <span @click="toSetting">
                    <i class="fal fa-cog" />
                    {{ $t("x_nav_setting") }}
                   </span>
                </DropdownItem>

                <DropdownItem>
                    <span @click="goToHubPage">
                        <i class="fal fa-building" />
                        {{ $t("x_change_hub") }}
                    </span>
                </DropdownItem>

                <Dropdown placement="left-start" class="sdk-dropdown">
                    <DropdownItem>
                        <i class="fal fa-file" />
                        SDK
                        <i class="fal fa-angle-right" />
                    </DropdownItem>
                    <template #list>
                        <DropdownMenu>
                            <DropdownItem>
                                <span @click="toSDK('https://geotwin.cc/gt/GTViewerSDK/documentation/Viewer.html')">
                                    GTViewerSDK
                                </span>
                            </DropdownItem>
                            <DropdownItem>
                                <div @click="toSDK('https://geotwin.cc/gt/GTViewerSDK/default/examples.html#data')">
                                    GTViewerSDK Demo
                                </div>
                            </DropdownItem>
                            <DropdownItem>
                                <span
                                    @click="toSDK('https://documenter.getpostman.com/view/4649116/2s93XsZ6zw#9c42b669-dc64-4ad1-a2c0-a823390d76ef')"
                                >
                                    OpenAPI
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </template>
                </Dropdown>

                <DropdownItem>
                    <span @click="logout">
                        <i class="fal fa-power-off" /> {{ $t("x_log_out") }}
                    </span>
                </DropdownItem>
            </DropdownMenu>
        </template>
    </Dropdown>
</template>

<style scoped lang="less">
.sdk-dropdown :deep(.ivu-select-dropdown) {
    width: 166px;
}
</style>
