import Cookies from "js-cookie";
import $ from "jquery";
import Util from "./util.js";

const service = {};
const pathexclude = [
    "/file/resume",
    "/file/check",
    "/sys/viewComment/save",
    "/progress/clean",
    "/sys/layer/handle/obj",
    "/sys/layer/handle/gltf",
    "/sys/layer/tag/update",
];

service.request = function (
    method,
    url,
    data,
    success_callback,
    error_callback,
    options,
) {
    options = options || {};
    const baseUrl = window.Baseurl;
    // if (url.startsWith("/oss") && window.ossflag) {
    //     const subdomain = window.ossPrefix;
    //     const baseHost = `https://${subdomain}geotwin.cn`;
    //     baseUrl = `${baseHost}${window.baseAPI}`;
    // }
    $.ajax({
        url: url.includes("https:") ? url : `${baseUrl}${url}`,
        data: method !== "get" ? JSON.stringify(data) : data,
        dataType: options.dataType ? options.dataType : "json",
        contentType: options.contentType,
        crossDomain: true,
        beforeSend(request) {
            request.setRequestHeader(
                "accept-language",
                window.localStorage.language,
            );

            if (method !== "get") {
                request.setRequestHeader(
                    "Content-type",
                    "application/json;charset=UTF-8",
                );
            }
            if (Cookies.get("sdms_token")) {
                request.setRequestHeader("token", Cookies.get("sdms_token"));
            }
            else if (Cookies.get("workspace_token")) {
                request.setRequestHeader(
                    "token",
                    Cookies.get("workspace_token"),
                );
            }
        },
        xhrFields: {
            withCredentials: true,
        },
        method,
        success(dt) {
            if (dt.code === 0) {
                success_callback(dt);
                if (dt.msg && method !== "get") {
                    if (!_.includes(pathexclude, url)) {
                        // window.systemVue.$Message.success(window.systemVue.$t(dt.msg));
                        if (!options.skipSuccess)
                            window.systemVue.$Message.success(dt.msg);
                    }
                }
            }
            else if (dt.code === 401) {
                window.systemVue.$emit(
                    "sys_err",
                    window.systemVue.$t(dt.msg),
                );
                window.systemVue.$Message.destroy();
                window.systemVue.$Message.error(dt.msg);
                window.systemVue.$store.commit("user/logout");
                window.systemVue.$router.push({
                    name: "login",
                });
            }
            else {
                if (error_callback)
                    error_callback(dt);

                if (dt.msg) {
                    // show err msg
                    // window.systemVue.$emit("sys_err", window.systemVue.$t(dt.msg));
                    if (!options.skipServerError) {
                        window.systemVue.$emit("sys_err", dt.msg);
                        window.systemVue.$Message.destroy();
                        window.systemVue.$Message.error({
                            content: dt.msg,
                            duration: 5,
                            closable: true,
                        });
                    }
                }
            }
        },
        complete() { },
        error(err) {
            console.error(err);
            // show err msg
            if (!options.skipServerError)
                window.systemVue.$emit("sys_err", err);

            if (error_callback)
                error_callback(err);
        },
    });
};

service.requestFrom = function (
    method,
    url,
    data,
    success_callback,
    error_callback,
    options,
) {
    options = options || {};

    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
            const dt = JSON.parse(xmlHttp.responseText);
            success_callback(dt);
            if (dt.msg) {
                if (!_.includes(pathexclude, url)) {
                    // window.systemVue.$Message.success(window.systemVue.$t(dt.msg));
                    if (!options.skipSuccess)
                        window.systemVue.$Message.success(dt.msg);
                }
            }
        }
        else {
            if (xmlHttp.status >= 400)
                error_callback();
        }
    };
    xmlHttp.open(_.upperCase(method), `${Baseurl}${url}`, true); // true for asynchronous
    if (Cookies.get("sdms_token"))
        xmlHttp.setRequestHeader("token", Cookies.get("sdms_token"));
    else if (Cookies.get("workspace_token"))
        xmlHttp.setRequestHeader("token", Cookies.get("workspace_token"));

    xmlHttp.setRequestHeader("accept-language", window.localStorage.language);
    /* xmlHttp.setRequestHeader(
    "Content-Type",
    "multipart/form-data; charset=UTF-8"
    ); */
    xmlHttp.withCredentials = true;
    xmlHttp.send(data);
};

// service.requestBuffer = function (method, url, form, success_callback, error_callback) {
service.requestBuffer = function (
    method,
    url,
    json,
    success_callback,
    error_callback,
) {
    let keyForm;
    if (json) {
        const requestJSONStr = JSON.stringify(json);
        const keyBufferPromise = Util.streamTranslator(
            Cookies.get("sdms_token"),
            requestJSONStr,
        );
        keyBufferPromise.then((keyBuffer) => {
            keyForm = new FormData();
            keyForm.append("key", keyBuffer);
            sendRequest();
        });
    }
    else { sendRequest(); }

    function sendRequest() {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
                // success_callback(xmlHttp.response);
                callback(xmlHttp.response);
            else if (xmlHttp.status >= 400)
                error_callback();
        };
        xmlHttp.open(method, `${Baseurl}${url}`, true);
        if (Cookies.get("sdms_token"))
            xmlHttp.setRequestHeader("token", Cookies.get("sdms_token"));
        else if (Cookies.get("workspace_token"))
            xmlHttp.setRequestHeader("token", Cookies.get("workspace_token"));

        xmlHttp.setRequestHeader(
            "accept-language",
            window.localStorage.language,
        );

        xmlHttp.withCredentials = true;
        xmlHttp.responseType = "arraybuffer";
        xmlHttp.send(keyForm);

        function callback(buffer) {
            const decryPromise = Util.streamTranslator(
                Cookies.get("sdms_token"),
                buffer,
            );
            decryPromise.then((respStr) => {
                try {
                    respStr = JSON.parse(respStr);
                }
                catch (e) { }
                success_callback(respStr);
            });
        }
    }
};

service.requestFileBuffer = function (
    method,
    url,
    json,
    success_callback,
    error_callback,
) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
            // success_callback(xmlHttp.response);
            success_callback(xmlHttp.response);
        else if (xmlHttp.status >= 400)
            error_callback();
    };
    xmlHttp.open(method, `${Baseurl}${url}`, true);
    if (Cookies.get("sdms_token"))
        xmlHttp.setRequestHeader("token", Cookies.get("sdms_token"));
    else if (Cookies.get("workspace_token"))
        xmlHttp.setRequestHeader("token", Cookies.get("workspace_token"));

    xmlHttp.setRequestHeader("accept-language", window.localStorage.language);
    xmlHttp.setRequestHeader("Content-type", "application/json");

    xmlHttp.withCredentials = true;
    xmlHttp.responseType = "arraybuffer";
    xmlHttp.send(JSON.stringify(json));
};

service.resetPwd = function (currentPwd, newPwd, newPwdDup) {
    return new Promise((resolve) => {
        if (newPwd !== newPwdDup && newPwd.length > 0) {
            resolve({
                isReset: false,
                msg: "x_signup_repwd_error",
            });
        }
        else {
            const pwdResetObj = {
                password: currentPwd,
                newPassword: newPwd,
            };

            service.request(
                "put",
                "/sys/user/password",
                pwdResetObj,
                (dt) => {
                    if (dt.code >= 400) {
                        resolve({
                            isReset: false,
                            msg: dt.msg,
                        });
                    }
                    else {
                        resolve({
                            isReset: true,
                        });
                    }
                },
                () => {
                    resolve({
                        isReset: false, // service.js already pumping the alert, do not need to tell the promise to do so again
                    });
                },
            );
        }
    });
};

service.isCreator = function (id) {
    const uid = Number(Cookies.get("userId"));
    const roleAccess = JSON.parse(localStorage.roleAccess);
    return !!(id === uid || roleAccess.SUPER_ADMIN || roleAccess.ADMIN || roleAccess.PROJECT_ADMIN);
};

service.isCreatorUser = function (id) {
    const uid = Number(Cookies.get("userId") || roleAccess.SUPER_ADMIN);
    return id === uid;
};
export default service;
