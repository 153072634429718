<script setup>
import { ref } from "vue";
import TeleportEsm from "vue2-teleport";
import GlobalUploader from "@/components/GlobalUploader/GlobalUploader.vue";
import Bus from "../../libs/bus";

const uploadProgressCount = ref(0);
const isGlobalUploaderShow = ref(false);
const globalUploader = ref(null);

const uploadListIconToRight = ref(0);



function uploadPoptipClick(flag) {
    console.log("uploadPoptipClick",flag);
    if (typeof flag === "boolean")
        isGlobalUploaderShow.value = flag;
    else
        isGlobalUploaderShow.value = !isGlobalUploaderShow.value;

    if (isGlobalUploaderShow.value) {
        globalUploader.value.panelShow = true;
        const uploadPoptipButton = document.getElementById(
            "upload-poptip-button",
        );

        // 获取元素的边界框信息
        const rect = uploadPoptipButton.getBoundingClientRect();

        // 获取元素到浏览器视口最右边的距离
        uploadListIconToRight.value = window.innerWidth - rect.right;
    }
}

function progressCountChange(num) {
    // 右上角徽标数量变化
    uploadProgressCount.value = num;
    if (uploadProgressCount.value === 0)
        isGlobalUploaderShow.value = false;
}

Bus.$on("uploadPoptipClick",uploadPoptipClick);

Bus.$on("progressCountChange",progressCountChange);
</script>

<template>
    <Badge :count="uploadProgressCount">
        <Button
            id="upload-poptip-button"
            type="text"
            class="upload-list-btn"
            @click.stop="uploadPoptipClick"
        >
            <i class="fal fa-cloud-upload" />
        </Button>
        <TeleportEsm to="body">
            <GlobalUploader
                v-show="isGlobalUploaderShow"
                ref="globalUploader"
                :style="{ right: `${uploadListIconToRight}px` }"
                @upload-poptip-click="uploadPoptipClick"
                @progress-count-change="progressCountChange"
            />
        </TeleportEsm>
    </Badge>
</template>

<style scoped lang="less">
.upload-list-btn {
    padding-right: 4px;
    color: white;
}
</style>
