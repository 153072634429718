const assetRoutes = [
    {
        path: "/asset",
        name: "asset",
        meta: {
            title: "x_assets",
        },
        component: () => import("@/pages/asset/index.vue"),
    },
];

export default assetRoutes;
