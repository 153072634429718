import useWeChatLogin from "@/hooks/useWeChatLogin.js";

const { isToWeChatBind } = useWeChatLogin();

const accountRoutes = [
    {
        path: "/account/usersList",
        name: "account-userList",
        meta: {
            title: "x_user_management",
        },
        component: () => import("@/pages/account/accountUser.vue"),
    },
    {
        path: "/account/menuList",
        name: "account-menuList",
        meta: {
            title: "x_menu_management",
        },
        component: () => import("@/pages/account/accountMenu.vue"),
    },
    {
        path: "/account/roleList",
        name: "account-roleList",
        meta: {
            title: "x_role_management",
        },
        component: () => import("@/pages/account/accountRole.vue"),
    },
    {
        path: "/account/department",
        name: "account-department",
        meta: {
            title: "x_department_management",
        },
        component: () => import("@/pages/account/accountDepartment.vue"),
    },
    {
        path: "/account/profile",
        name: "account-profile",
        meta: {
            title: "x_menu_my_profile",
        },
        component: () => import("@/pages/account/MyProfile.vue"),
        beforeEnter: (to, from, next) => {
            if (isToWeChatBind()) {

            }
            return next();
        },
    },
    {
        path: "/account/companyProfile",
        name: "account-companyProfile",
        meta: {
            title: "x_menu_company_profile",
        },
        component: () => import("@/pages/account/CompanyProfile.vue"),
    },
    {
        path: "/account/companyLayer",
        name: "account-companyLayer",
        meta: {
            title: "x_base_layer_setting",
        },
        component: () => import("@/pages/account/AccountLayer.vue"),
    },
    {
        path: "/account/noticesList",
        name: "account-noticesList",
        meta: {
            title: "x_menu_my_massage",
        },
        component: () => import("@/pages/account/AccountNotice.vue"),
    },
    {
        path: "/account/log",
        name: "account-log",
        meta: {
            title: "x_log_management",
        },
        component: () => import("@/pages/account/AccountLog.vue"),
    },
    {
        path: "/account/dashboardTemplate",
        name: "account-dashboard-template",
        meta: {
            title: "x_template_management",
        },
        component: () => import("@/pages/account/DashboardTemplate.vue"),
    },
    // {
    //     path: "/account/aiModel",
    //     name: "account-ai-model",
    //     component: () => import("@/pages/projects/ai/models.vue"),
    // },
];

export default accountRoutes;
