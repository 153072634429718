<script setup>
import { computed, getCurrentInstance, ref, watchEffect } from "vue";
import useCaptcha from "@/hooks/useCaptcha.js";
import useAuth from "@/hooks/useAuth.js";
import store from "@/store/index.js";
import useWeChatLogin from "@/hooks/useWeChatLogin.js";

const emits = defineEmits(["to-signup", "to-forget-pwd"]);

const { captchaUrl, uuid, updateCaptcha, loading: captchaLoading } = useCaptcha();

const { loading, loginData, login: _login } = useAuth();
watchEffect(() => {
    loginData.value.uuid = uuid.value;
});

const el = getCurrentInstance();

const deviceType = computed(() => {
    return store.state.deviceType;
});

const login_form = ref(null);

function loginFormValidateUsername(rule, value, callback) {
    // var email = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
    const email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/;

    if (!value)
        return callback(new Error(el.proxy.$t("x_username_empty_err")));
    else if (!email.test(value))
        callback(new Error(el.proxy.$t("x_email_invlidate")));
    else
        return callback();
}

function validatePassword(rule, value, callback) {
    if (!value)
        return callback(new Error(el.proxy.$t("x_password_empty_err")));
    else
        return callback();
}

function validateEmpty(rule, value, callback) {
    if (!value)
        return callback(new Error(el.proxy.$t("x_empty_err")));
    else
        return callback();
}

const rules = {
    username: [
        { validator: loginFormValidateUsername, trigger: "none" },
    ],
    password: [
        { validator: validatePassword, trigger: "none" },
    ],

    captcha: [
        { validator: validateEmpty, trigger: "none" },
    ],
};
function login() {
    if (captchaLoading.value)
        return;
    login_form.value.validate((valid) => {
        if (valid) {
            const options = {};
            console.log(el.proxy.$route.query);
            if (el.proxy.$route.query?.openId)
                options.openId = el.proxy.$route.query.openId;

            _login(options).catch(() => {
                updateCaptcha();
            });
        }
    });
}

function loginWithGoogle() {
    window.location.href = window.authUrl;
}

const { toWeChatLogin } = useWeChatLogin();

function loginWithWechat() {
    const wxLogin = toWeChatLogin("wx-login-qrcode-container");
    console.log(wxLogin);
}

const isCn = computed(() => {
    return store.state.isCn;
});
</script>

<template>
    <div style="position: relative">
        <div class="login-header">
            <h1>
                {{ $t("x_login_site_title") }}
            </h1>
        </div>
        <i-form ref="login_form" :model="loginData" :rules="rules" label-position="top" @keyup.down="login">
            <FormItem prop="username" :label="$t('x_username_or_email')">
                <Input v-model="loginData.username" size="large" :placeholder="$t('x_username_or_email')" />
            </FormItem>
            <FormItem prop="password" :label="$t('x_password')">
                <Input v-model="loginData.password" size="large" type="password" password :placeholder="$t('x_password')" />
            </FormItem>
            <FormItem prop="captcha" :label="$t('x_captcha_text')">
                <Row>
                    <Col span="14">
                        <Input v-model="loginData.captcha" size="large" @keydown.enter.native="login" />
                    </Col>
                    <Col span="8" :offset="2">
                        <div style="position: relative;cursor: pointer;height: 40px">
                            <img :src="captchaUrl" alt="refresh" title="refresh" style="width: 100%" @click="updateCaptcha">
                            <Spin v-show="captchaLoading" fix />
                        </div>
                    </Col>
                </Row>
            </FormItem>
            <FormItem>
                <Button type="primary" long size="large" @click="login">
                    {{ $t("x_login") }}
                </Button>
            </FormItem>
        </i-form>
        <Divider>Or</Divider>
        <Row>
            <Poptip v-if="isCn" id="wx-login-poptip" style="width: 100%">
                <Button size="large" long @click="loginWithWechat">
                    <span style="margin-top: 6px;display: flex;flex-direction: row;align-items: center;font-size: 14px">
                        <svg style="margin-right: 8px;width: 26px;height: 26px" aria-hidden="true">
                            <use xlink:href="#icon-weixin" />
                        </svg>
                        {{ $t("x_using_wechat_login") }}
                    </span>
                </Button>
                <template #content>
                    <div id="wx-login-qrcode-container" />
                </template>
            </Poptip>

            <Button v-else size="large" long @click="loginWithGoogle">
                <span style="margin-top: 6px;display: flex;flex-direction: row;align-items: center;font-size: 14px">
                    <svg style="margin-right: 8px;width: 24px;height: 24px" aria-hidden="true">
                        <use xlink:href="#icon-guge" />
                    </svg>
                    {{ $t('x_using_google_login') }}
                </span>
            </Button>
        </Row>
        <Row type="flex" justify="center" style="margin-top: 1.2rem">
            <p style="font-size: 12px">
                {{ $t("x_dont_have_account") }}<a @click.prevent="emits('to-signup')">{{ $t("x_signup") }}</a>
            </p>
        </Row>
        <Row type="flex" justify="center" style="margin-top: 0.8rem">
            <p style="font-size: 12px">
                <a @click.prevent="emits('to-forget-pwd')">{{ $t("x_pwd_reset") }}</a>
            </p>
        </Row>
        <Spin v-if="loading" fix />
    </div>
</template>

<style scoped lang="less">
.login-header{
    margin-bottom: 20px;
}

.google-icon {
    margin-right: @gt-space-1;
    width: 24px;
    height: 24px;
}

#wx-login-qrcode-container {
    //width: 10rem;
    //height: 10rem;
}
</style>

<style>
#wx-login-poptip .ivu-poptip-rel {
    width: 100%;
}
</style>
