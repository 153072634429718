import { ref } from "vue";
import Service from "@/libs/service.js";

export default function useCaptcha() {
    const loading = ref(false);
    const captchaUrl = ref("");
    const uuid = ref(void 0);

    updateCaptcha();

    function updateCaptcha() {
        loading.value = true;
        return new Promise((resolve, reject) => {
            Service.request(
                "get",
                `/sys/captcha`,
                {},
                (res) => {
                    captchaUrl.value = res.data.image;
                    uuid.value = res.data.uuid;
                    loading.value = false;
                    resolve(res.data);
                },
                (err) => {
                    loading.value = false;
                    reject(err);
                },
            );
        });
    }

    function checkCaptcha(captcha) {

    }

    return {
        captchaUrl,
        uuid,
        updateCaptcha,
        loading,
    };
}
