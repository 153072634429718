import { computed, getCurrentInstance } from "vue";
import store from "@/store/index.js";
import { THEME } from "@/constant/constant.js";

export default function useTheme() {
    const el = getCurrentInstance();

    const theme = computed(() => {
        return store.state.user.theme;
    });

    function setTheme(themeName) {
        store.commit("user/setTheme", themeName);
        // el.proxy.$addStorageEvent(1, "theme", themeName);
    }

    function changeTheme(themeName) {
        return store.dispatch("user/changeTheme", themeName).then(() => {
            // el.proxy.$addStorageEvent(1, "theme", themeName);
        });
    }

    return {
        theme,
        setTheme,
        changeTheme,
        THEME,
    };
}
