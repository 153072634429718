const topDomain = window.location.hostname.split(".").at(-1);

const appConfig = {
    title: "GeoTwin空间孪生计算平台",
    // wechatRedirectOrigin: window.location.origin,
    wechatRedirectOrigin: "https://geotwin.cn",
    wxAppId: "wx5aa5ae28feac6b9b",
    ossBaseUrl: import.meta.env.VITE_OSS_BASE_URL,
};

export {
    appConfig,
};
