<script>
import { computed, getCurrentInstance, ref, watchEffect } from "vue";
import LeftMenu from "./leftMenu.vue";
import MessageBtn from "@/views/appHeader/MessageBtn.vue";
import ThemeBtn from "@/views/appHeader/ThemeBtn.vue";
import AppHeaderUser from "@/views/appHeader/AppHeaderUser.vue";
import store from "@/store/index.js";
import UploadListBtn from "@/views/appHeader/UploadListBtn.vue";
import Bus from "@/libs/bus.js";

export default {
    components: {
        UploadListBtn,
        AppHeaderUser,
        ThemeBtn,
        MessageBtn,
        LeftMenu,
    },
    setup() {
        const el = getCurrentInstance();

        const menuAccess = computed(() => {
            return store.state.user.menuAccess;
        });

        const routerName = computed(() => {
            return store.state.routerName;
        });

        const menuList = [
            {
                icon: "icon iconfont-gt icon-xiangmuguanli",
                title: "x_project_mgn",
                type: "project",
                permission: "sys_project_management_view",
            },
            {
                icon: "icon iconfont-gt icon-shujuguanli",
                title: "x_data_mgn",
                type: "data",
                permission: "sys_data_management",
            },
            {
                icon: "icon iconfont-gt icon-zichanguanli",
                title: "x_asset_mgn",
                type: "asset",
                permission: "sys_account_management_view",
            },
        ];

        const currentMenu = ref("project");

        watchEffect(() => {
            let res = "";
            const routePrefix = routerName.value.split("-")[0];

            const isExist = menuList.some((item) => {
                return item.type === routePrefix;
            }) || routePrefix === "account";
            if (isExist && el.proxy)
                res = routePrefix;
            currentMenu.value = res;
        });

        store.dispatch("company/queryCompanyInfo");

        return {
            menuAccess,
            menuList,
            currentMenu,
        };
    },
    data() {
        return {
            // currentMenu: "project",
            showMenuTitle: true,
        };
    },
    computed: {
        companyInfo() {
            return this.$store.state.company.companyInfo;
        },
    },
    created() {
    },
    mounted() {
        $("body").click((e) => {
            if ($(e.target).closest(".file-list").length)
                return;

            Bus.$emit("uploadPoptipClick", false);
        });
    },
    methods: {
        getMenu(val) {
            console.log("val", val);
            if (!this.menuList.find(item => item.type === val) && val !== "account")
                val = "";

            this.currentMenu = val;
            this.$refs.leftMenu.getRefreshMenu(val);
        },
        isShowMenuTitle() {
            this.showMenuTitle = !this.showMenuTitle;
            this.$refs.leftMenu.showMenuTitle = this.showMenuTitle;
        },
    },
};
</script>

<template>
    <div id="menuIndex">
        <div class="top-menu">
            <div class="left">
                <div class="logo-card">
                    <div class="logo">
                        <div class="logo-back">
                            <img class="logo-img" :src="companyInfo.logo" alt="img">
                        </div>
                        <span class="logo-title">
                            {{ companyInfo.name }}
                        </span>
                    </div>
                </div>
                <Menu class="menu-card" mode="horizontal" :active-name="currentMenu" @on-select="getMenu">
                    <MenuItem v-for="(item, index) in menuList" v-show="menuAccess[item.permission]" :key="index" :name="item.type">
                        <i :class="item.icon" />
                        {{ $t(item.title) }}
                    </MenuItem>
                </Menu>

                <!-- 通过 dispaly 控制 移动端才会显示 -->
                <Poptip class="mobile-menu-card" placement="bottom-start">
                    <div style="display: flex;align-items: center;gap:4px">
                        <Icon type="ios-menu" style="font-size: 26px;" />
                        <span>Menu</span>
                    </div>
                    <template #content>
                        <div class="poptip-content">
                            <Menu theme="light" :active-name="currentMenu" @on-select="getMenu">
                                <MenuItem v-for="(item, index) in menuList" v-show="menuAccess[item.permission]" :key="index" :name="item.type">
                                    <i :class="item.icon" />
                                    {{ $t(item.title) }}
                                </MenuItem>
                            </Menu>
                        </div>
                    </template>
                </Poptip>
            </div>

            <div class="nav-setting">
                <UploadListBtn />
                <MessageBtn />
                <ThemeBtn />
                <AppHeaderUser @to-account="() => getMenu('account')" />
            </div>
        </div>
        <div class="bottom-menu">
            <LeftMenu ref="leftMenu" />
        </div>
    </div>
</template>

<style lang="less" scoped>
#menuIndex{
    width: 100%;
    height: 100%;
    .top-menu{
        padding: 0 @gt-page-padding-1;
        position: relative;
        width: 100%;
        height: 50px;
        background-color: @app-header-bg-color;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,.1);

        .ivu-menu-horizontal{
            height: 50px !important;
            line-height: 50px !important;

            &:after {
                background: transparent !important;
            }
        }
        .ivu-menu-light{
            background-color:transparent !important;
        }
        @media (min-width: 736px)  {
            .ivu-menu-item{
                //color: @gt-text-color-1;
                color: #fff;
                font-size: 14px;
            }

        }

        .ivu-menu-item-selected{
            color: @gt-brand-6;
            background-color: @gt-bg-color-2 !important;
        }

        .left {
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo-card{
                flex-shrink: 0;
                min-width: 204px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .logo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .logo-back{
                        background-color: #FFFFFF;
                        width: 32px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        overflow: hidden;
                        .logo-img{
                            width: 39px;
                            height: 23px;
                        }
                    }

                    .logo-title{
                        font-size: 20px;
                        color: white;
                        margin-left: 10px;
                    }
                }

                .menu-fold-icon{
                    margin-right: @gt-space-1;
                    cursor: pointer;
                }
            }
            @media (max-width: 1000px) {
                .logo-card {
                    min-width: 0px;
                    .logo-title {
                        display: none;
                    }
                }
            }
            .menu-card{
                margin-left: @gt-page-padding-1;
                height: 50px;
                //width: calc(100% - 224px - 20%);
            }
            // 默认不显示 屏幕小于一定程度才显示
            .mobile-menu-card {
                display: none;
            }
        }

        .nav-setting {
            font-size: 14px;
            color: @gt-text-color-5;
            margin-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            .icon-notification,.icon-moon{
                cursor: pointer;
                color: #FFFFFF;
                padding: 0 20px 0 0;
            }
            .username-card{
                width: 130px;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #FFFFFF;
                cursor: pointer;
                .icon-down-icon{
                    margin-left: 20px;
                }
                .username-text{
                    height: 24px;
                    width: 24px;
                    background-color: #FFFFFF;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 50%;
                    margin: 5px;
                }
                .username-tag{
                    color: @gt-text-color-1;
                }
            }
        }
    }

    .bottom-menu{
        display: flex;
        flex-direction: row;
        height: calc(100% - 50px);
        .left-menu{
            width: 224px;
        }
        .right-menu{
            width: 100%;
        }
    }
}

@media (max-width: 736px) {
    #menuIndex {
        .top-menu {
            padding: 0 20px;
            .left {
                .menu-card {
                    display: none;
                }
                .mobile-menu-card {
                    display: block;
                    margin-left: 22px;
                    color: white;

                }
            }
        }
    }
}
</style>
