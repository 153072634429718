/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
    return /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/.test(
        s,
    );
}

export function isLoginEmail(s) {
    return /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(
        s,
    );
}

/**
 * 特殊字符
 * @param {*} s
 */
export function isSpecial(s) {
    return /[~'<>#%^&*\[\]\{\}]/g.test(s);
}

/**
 * 非法字符
 * @param {*} s
 */
export function isIllegal(s) {
    return /\b(master|truncate|insert|select|delete|update|declare|alter|drop)\b/g.test(
        s,
    );
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
    const phoneRegEx = {
        cn: /^1[3-9]\d{9}$/, // 中国大陆
        hk: /^(\+852\-)?[569]\d{3}\-?\d{4}$/, // 中国香港
        tw: /^(\+886\-)?(9\d{8}|[2-8]\d{7})$/, // 中国台湾
    };
    // return /^1[0-9]{10}$/.test(s)
    return phoneRegEx.cn.test(s) || phoneRegEx.hk.test(s) || phoneRegEx.tw.test(s);
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
    // return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
    return true;
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
    return /^http[s]?:\/\/.*/.test(s);
}

export function gup(name, url) {
    if (!url)
        url = location.href;
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = `[\\?&]${name}=([^&#]*)`;
    let regex = new RegExp(regexS);
    const results = regex.exec(url);
    regex = undefined;
    return results == null ? null : results[1];
}

export function calcPasswordStrength(password = "", options = {}) {
    const { email = "" } = options;
    // 初始化状态。UI上提示颜色分三种，所以验证结果有三种状态，对应三种颜色
    // default 默认状态，对应灰色
    // succes 通过规则状态，对应绿色
    // error 未通过规则的状态，对应红色
    const rulesStatus = {
        0: "default",
        1: "default",
        2: "default",
        3: "default",
    };
    // 必须包含8-20个字符
    if (password.length >= 6 && password.length <= 20)
        rulesStatus[0] = "success";

    // 必须同时包含大小写字母和数字和特殊符号
    if (
        /(?=.*[a-z])/.test(password)
        && /(?=.*[A-Z])/.test(password)
        && /(?=.*[-_~!@#$%^&*=+()[\]{}])/.test(password)
    )
        rulesStatus[1] = "success";

    // 不能包含用户邮箱后缀之前的字符
    const emailArray = email.split("@") || [];
    if (!email || password !== emailArray[0])
        rulesStatus[2] = "success";

    // 不能过于常见（不能出现连续4位或以上满足字母表顺序的字母如abcd、
    // 连续4位或以上0-9数字如4321 ，不能有连续有4位或以上相同的字符如6666）
    const isRepeat = calcPasswrodRepeat(password);
    if (!isRepeat)
        rulesStatus[3] = "success";

    return rulesStatus;
}

function calcPasswrodRepeat(password) {
    let isRepeat = false;
    const repeat = {};
    for (let index = 0; index < password.length; index++) {
        const preIndex = index - 1 < 0 ? 0 : index - 1;
        const preStr = repeat[preIndex] || "";
        const preStrLastLetter = preStr[preStr.length - 1] || "";
        const currentLetter = password[index];
        const preStrLastLetterCharCode = preStrLastLetter.charCodeAt();
        const curCharCode = currentLetter.charCodeAt();
        if (
            curCharCode - preStrLastLetterCharCode === 1 // 数字或字母递增
        ) {
            if (preStr.length <= 1) {
                repeat[index] = `${preStr}${currentLetter}`;
            }
            else {
                let start = 0;
                for (let j = preStr.length - 2; j > 0; j--) {
                    if (preStr[j + 1].charCodeAt() - preStr[j].charCodeAt() !== 1) {
                        start = j + 1;
                        break;
                    }
                }
                const result = `${preStr.substring(start)}${currentLetter}`;
                repeat[index] = result;
                if (result.length >= 4)
                    isRepeat = true;
            }
        }
        else if (currentLetter === preStrLastLetter) { // 数字或者字母重复
            let repeatStart = 0;
            for (let j = preStr.length - 1; j > 0; j--) {
                if (preStr[j] !== currentLetter) {
                    repeatStart = j + 1;
                    break;
                }
            }
            const result = `${preStr.substring(repeatStart)}${currentLetter}`;
            repeat[index] = result;
            if (result.length >= 4)
                isRepeat = true;
        }
        else if (preStrLastLetterCharCode - curCharCode === 1) { // 数字或字母递减
            if (preStr.length <= 1) {
                repeat[index] = `${preStr}${currentLetter}`;
            }
            else {
                let start = 0;
                for (let j = preStr.length - 2; j > 0; j--) {
                    if (preStr[j].charCodeAt() - preStr[j + 1].charCodeAt() !== 1) {
                        start = j + 1;
                        break;
                    }
                }
                const result = `${preStr.substring(start)}${currentLetter}`;
                repeat[index] = result;
                if (result.length >= 4)
                    isRepeat = true;
            }
        }
        else {
            repeat[index] = currentLetter;
        }
    }
    return isRepeat;
}
