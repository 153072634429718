import { ref } from "vue";
import Cookies from "js-cookie";
import Service from "@/libs/service.js";
import { appConfig } from "@/config/config.js";

const subDomain = ref("");

export default function useWeChatLogin() {
    const appId = appConfig.wxAppId;
    const redirect_uri = appConfig.wechatRedirectOrigin;
    // const redirect_uri = "http://localhost:8080";

    // function toWeChatLogin() {
    //     const state = `wechat_login::${window.subdomainName}`;
    //     console.log(subDomain.value);
    //     window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=${state}#wechat_redirect`;
    // }

    function toWeChatLogin(containerId) {
        return new WxLogin({
            self_redirect: false,
            id: containerId,
            appid: appConfig.wxAppId,
            scope: "snsapi_login",
            redirect_uri: `${appConfig.wechatRedirectOrigin}`,
            state: `wechat_login::${window.subdomainName}`,
            style: "black",
            // href: `${window.location.origin}/style/wxLoginStyle.css`,
            // stylelite: 1,
        });
    }

    function isWeChatLogin() {
        const state = new URLSearchParams(window.location.search).get("state");
        const flag = state?.split("::")[0];
        return window.location.search.includes("code") && flag === "wechat_login";
    }

    async function loginWithWeChat() {
        if (!isWeChatLogin()) {
            console.error("Not in WeChat login state");
            return null;
        }
        const code = new URLSearchParams(window.location.search).get("code");
        const state = new URLSearchParams(window.location.search).get("state");
        const params = state.split("::");
        const subDomain = params[1];
        return new Promise((resolve, reject) => {
            const data = {
                code,
            };
            if (subDomain !== "undefined") {
                data.website = subDomain;
                if (location.hostname === "geotwin.cn" || location.hostname === "www.geotwin.cn") {
                    const hostname = `${subDomain}.geotwin.cn`;
                    window.location.href = window.location.href.replace(window.location.hostname, hostname);
                    return;
                }
            }

            Service.request(
                "post",
                "/sys/login/weixin",
                data,
                (res) => {
                    if (res.data.token)
                        Cookies.set("sdms_token", res.data.token);

                    resolve(res);
                    localStorage.removeItem("wx_subdomain");
                },
                (err) => {
                    reject(err);
                    localStorage.removeItem("wx_subdomain");
                },
            );
        });
    }

    function isToWeChatBind() {
        const state = new URLSearchParams(window.location.search).get("state");
        const flag = state?.split("::")[0];
        return window.location.search.includes("code") && flag === "wechat_bind";
    }

    async function bindWeChat() {
        const state = new URLSearchParams(window.location.search).get("state");
        const params = state.split("::");
        const subDomain = params[3];
        if (location.hostname === "geotwin.cn" || location.hostname === "www.geotwin.cn") {
            const hostname = `${subDomain}.geotwin.cn`;
            window.location.href = window.location.href.replace(window.location.hostname, hostname);
        }

        const token = params[1];
        if (token)
            Cookies.set("sdms_token", token);
        else
            console.error("No token found in state");
        const email = params[2];
        const code = new URLSearchParams(window.location.search).get("code");
        return new Promise((resolve, reject) => {
            Service.request(
                "post",
                "/users/wechat",
                {
                    code,
                    email,
                },
                (res) => {
                    if (res.code === 0)
                        resolve(res);
                    else
                        reject(res.msg);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    return {
        toWeChatLogin,
        isWeChatLogin,
        loginWithWeChat,
        isToWeChatBind,
        bindWeChat,
    };
}
