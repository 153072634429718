const shareRoutes = [
    {
        path: "/share",
        component: () => import("@/pages/share/shareAuth.vue"),
        children: [
            {
                path: "scene",
                name: "share-scene",
            },
            {
                path: "dashboard",
                name: "share-dashboard",
            },
            {
                path: "external",
                name: "share-storage",
            },
        ],
    },
];

export default shareRoutes;
