import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import routes from "./routes/routes.js";
import store from "@/store/index.js";
import Util from "@/libs/util";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if (localStorage?.theme && localStorage.theme !== "undefined")
        store.commit("user/setTheme", localStorage.theme);
    else
        store.commit("user/setTheme", "sdmslight");

    if (localStorage?.language && localStorage.language !== "undefined")
        store.commit("user/setLanguage", localStorage.language);
    else
        store.commit("user/setLanguage", "zh-CN");

    store.commit("autoSetIsCn");

    window.root_platform = true;
    let subdomain = "vr3d5-dev.";
    const hostitems = window.location.host.split(".");
    const isLocalhost = window.location.host.includes("localhost");
    if (window.isSubDomainSupport) {
        if (isLocalhost && hostitems.length > 1) {
            subdomain = `${hostitems[0]}.`;
            window.root_platform = false;
            window.subdomainName = hostitems[0];
        }
        else if (!isLocalhost && hostitems.length > 2) {
            subdomain = `${hostitems[0]}.`;
            window.root_platform = false;
            window.subdomainName = hostitems[0];
        }
    }
    else {
        window.subdomainName = ""; // Or hardcode this to a specific hub name
    }
    window.subdomain = subdomain;
    window.baseHost = `https://${subdomain}geotwin.cn`;
    // window.baseHost = `http://192.168.10.131:8082`;
    // const baseAPI = "/geoface";
    const baseAPI = "/geotwin";
    window.baseAPI = baseAPI;
    window.Baseurl = `${window.baseHost}${baseAPI}`;
    window.socketSource = `${subdomain}geotwin.cn${baseAPI}`;
    window.iserverUrl = "https://vr3d.cloud";
    window.proxyUrl = "https://vr3d.cloud";
    window.clientId = "680145680831-qgme6gm94paibm5s3bvp352iqcdf2dvt.apps.googleusercontent.com"; // 你的 Google OAuth 客户端 ID
    window.redirectUri = "http://localhost:8080"; // 重定向 URI

    window.scope = "email profile"; // 请求的权限范围，可以根据需求修改
    window.state = "YOUR_STATE"; // 用于防止跨站请求伪造（CSRF）攻击，可以不设置
    window.responseType = "code"; // 授权响应类型，表示要求返回授权码
    window.clientSecret = "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"; // Google OAuth 客户端密钥
    window.grantType = "authorization_code";
    // &prompt=login
    window.authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${window.clientId}&redirect_uri=${window.redirectUri}&scope=${window.scope}&state=${window.state}&response_type=${window.responseType}`;

    if (!isLocalhost) {
        window.baseHost = window.location.origin;
        // const baseAPI = "/geoface";
        const baseAPI = "/geotwin";
        window.baseAPI = baseAPI;
        window.Baseurl = `${window.baseHost}${baseAPI}`;
        window.socketSource = `${window.location.host}${baseAPI}`;
        window.iserverUrl = "https://vr3d.cloud";
        window.proxyUrl = "https://vr3d.cloud";
        // 680145680831-qgme6gm94paibm5s3bvp352iqcdf2dvt.apps.googleusercontent.com
        // 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

        // 866356739033-ac5g9e57c2f53svqt6dlkecgtp4dqkkh.apps.googleusercontent.com
        // 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
        window.clientId = "680145680831-qgme6gm94paibm5s3bvp352iqcdf2dvt.apps.googleusercontent.com"; // 你的 Google OAuth 客户端 ID
        window.clientSecret = "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"; // Google OAuth 客户端密钥
        window.redirectUri = window.location.origin; // 重定向 URI
        window.scope = "email profile"; // 请求的权限范围，可以根据需求修改
        window.state = "YOUR_STATE"; // 用于防止跨站请求伪造（CSRF）攻击，可以不设置
        window.responseType = "code"; // 授权响应类型，表示要求返回授权码
        window.grantType = "authorization_code";
        // &prompt=login
        window.authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${window.clientId}&redirect_uri=${window.redirectUri}&scope=${window.scope}&state=${window.state}&response_type=${window.responseType}`;
    }

    // if (to.path !== from.path)
    // ViewUI.LoadingBar.start();

    Util.title(to);

    if (!Cookies.get("sdms_token")
        && to.name !== "login"
        && to.name !== "signup-success"
        && to.name !== "create-hub"
        && to.name !== "error"
        && to.name !== "apploading"
        && to.name !== "share-scene"
        && to.name !== "share-dashboard"
        && to.name !== "share-storage"
        && to.name !== "home"
        && to.name !== "geointroenus"
        && to.name !== "geointro"
    ) {
        let sv;

        if (to.query && to.query.sv)
            sv = to.query.sv;
        const nextObj = {
            name: "login",
        };
        if (sv) {
            nextObj.query = {
                sv,
            };
        }

        next(nextObj);
    }
    else if (Cookies.get("sdms_token") && to.name === "login") {
        if (window.root_platform) {
            next({
                name: "hub",
            });
        }
        else {
            next({
                name: "project-list",
            });
        }
    }
    else {
        Util.toDefaultPage([routes], to.name, router, next);
    }
});

router.afterEach(() => {
    store.commit("setRouterName", router.currentRoute.name);
});

export default router;
