import Cookies from "js-cookie";
import router from "@/router/index.js";
import Service from "@/libs/service.js";
import useWeChatLogin from "@/hooks/useWeChatLogin.js";
import store from "@/store/index.js";

const { isWeChatLogin, loginWithWeChat, isToWeChatBind, bindWeChat } = useWeChatLogin();

const homeRoutes = [
    {
        path: "/home",
        name: "home",
        meta: {
            title: "x_home",
        },
        // component: () => import("@/pages/geoface/face.vue"),
        component: () => import("@/pages/geoface/home.vue"),
        beforeEnter: (to, from, next) => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            if (!code) {
                return next();
            }
            else if (isToWeChatBind()) {
                bindWeChat().finally(() => {
                    // next({
                    //     name: "account-profile",
                    // });
                    window.location.href = "/#/account/profile";
                });
            }
            else if (isWeChatLogin()) {
                loginWithWeChat().then((res) => {
                    const data = res.data;
                    if (!data.token) {
                        const url = `/#/login?type=1&openId=${data.openId}`;
                        window.location.href = url;
                        return;
                    }
                    if (data.companyId === null) {
                        // next({
                        //     name: "hub",
                        // });
                        window.location.href = "/#/hub";
                    }
                    else {
                        Cookies.set("sdms_token", data.token);
                        const roleIds = data.roleIds;
                        const menuIds = data.menuIds;

                        Promise.all([
                            store.dispatch("user/queryUserInfo"),
                            store.dispatch("user/queryRoleAccess", roleIds),
                            store.dispatch("user/queryMenuAccess", menuIds),
                        ]).then(() => {
                            if (localStorage.redirectUrl) {
                                let query = {};
                                if (localStorage.redirectObj)
                                    query = JSON.parse(localStorage.redirectObj);

                                router.push({
                                    name: localStorage.redirectUrl,
                                    query,
                                });

                                localStorage.removeItem("redirectUrl");
                                localStorage.removeItem("redirectObj");
                                return;
                            }
                            if (localStorage.share_code && localStorage.share_p) {
                                Cookies.set("access", store.state.user.userInfo.roleIds.toString());
                                Cookies.set("user", store.state.user.userInfo.username);
                                Cookies.set("status", store.state.user.userInfo.status);
                                Cookies.set("userId", store.state.user.userInfo.userId);
                                Cookies.set("companyId", store.state.user.userInfo.companyId);

                                const shareHost
                                    = window.baseHost === ""
                                        ? `${window.location.protocol}//${window.location.host}`
                                        : window.baseHost;

                                const link = `${shareHost}${window.baseAPI}` + `/index/ws/${localStorage.share_code}?p=${localStorage.share_p}&userId=${store.state.user.userInfo.userId}`;
                                window.open(link, "_self");

                                localStorage.removeItem("share_code");
                                localStorage.removeItem("share_p");
                            }
                            else {
                                if (window.root_platform) {
                                    // router.push({
                                    //     name: "hub",
                                    // });
                                    window.location.href = "/#/hub";
                                }
                                else {
                                    Cookies.set("access", store.state.user.userInfo.roleIds.toString());
                                    Cookies.set("user", store.state.user.userInfo.username);
                                    Cookies.set("status", store.state.user.userInfo.status);
                                    Cookies.set("userId", store.state.user.userInfo.userId);
                                    Cookies.set("companyId", store.state.user.userInfo.companyId);

                                    Promise.resolve();
                                }
                            }
                        }).then(() => {
                            store.dispatch("user/queryUserInfo").then(() => {
                                Promise.resolve();
                            });
                        }).then(() => {
                            if (store.state.user.roleAccess.GUEST) {
                                // router.push({
                                //     name: "hub",
                                // });
                                window.location.href = "/#/hub";
                            }
                            else {
                                // router.push({
                                //     name: "project-overview",
                                // });
                                window.location.href = "/#/projectOverview";
                            }
                        });
                    }
                });
            }
            else {
                const tokenEndpoint = "https://oauth2.googleapis.com/token";
                const requestBody = new URLSearchParams();
                requestBody.append("code", code);
                requestBody.append("client_id", window.clientId);
                requestBody.append("client_secret", window.clientSecret);
                requestBody.append("redirect_uri", window.redirectUri);
                requestBody.append("grant_type", window.grantType);
                fetch(tokenEndpoint, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: requestBody,
                })
                    .then(response => response.json())
                    .then((data) => {
                        const accessToken = data.access_token;
                        fetch("https://www.googleapis.com/oauth2/v2/userinfo", {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        })
                            .then(response => response.json())
                            .then((userInfo) => {
                                // 在这里处理获取到的用户信息
                                // 'shenzhen1209@163.com'
                                // userInfo.email
                                const data = {
                                    email: userInfo.email,
                                };
                                Service.request("get", `/sys/login/google`, data, (dt) => {
                                    if (dt.data === "10011") { // 当前用户不存在
                                        window.systemVue.$Message.error(dt.msg);
                                        window.location.href = `${window.location.origin}/#/login?type=2`;
                                    }
                                    else if (dt.data === "10012") { // 当前用户不存在此公司
                                        window.systemVue.$Message.error(dt.msg);
                                        window.location.href = `${window.location.origin}/#/login?type=2`;
                                    }
                                    else if (dt.token) {
                                        Cookies.set("sdms_token", dt.token);
                                        if (dt.data.companyId == null) {
                                            router.push({
                                                name: "hub",
                                            });
                                        }
                                        else {
                                            const roleAccessList = {};
                                            const roleIds = dt.data.roleIds;
                                            const menuIds = dt.data.menuIds;
                                            Service.request("get", "/charactar/page?pageNo=1&pageSize=9999", "", (data) => {
                                                const roleData = data.data.list;
                                                if (roleData.length > 0) {
                                                    roleData.forEach((el) => {
                                                        roleAccessList[el.roleCode] = !!roleIds.includes(el.id);
                                                    });
                                                }
                                                localStorage.roleAccess = JSON.stringify(roleAccessList);
                                                const roleAccess = roleAccessList;
                                                const menuAccessList = {};
                                                Service.request("get", "/menu/selectByMenu", "", (data1) => {
                                                    const menuData = data1.data;
                                                    if (menuData.length) {
                                                        menuData.forEach((el) => {
                                                            menuAccessList[el.permission] = !!menuIds.includes(el.menuId);
                                                        });
                                                    }
                                                    localStorage.menuAccess = JSON.stringify(menuAccessList);
                                                    if (localStorage.redirectUrl) {
                                                        let query = {};
                                                        if (localStorage.redirectObj)
                                                            query = JSON.parse(localStorage.redirectObj);

                                                        router.push({
                                                            name: localStorage.redirectUrl,
                                                            query,
                                                        });

                                                        localStorage.removeItem("redirectUrl");
                                                        localStorage.removeItem("redirectObj");
                                                        return;
                                                    }
                                                    if (localStorage.share_code && localStorage.share_p) {
                                                        Cookies.set("access", dt.data.roleIds.toString());
                                                        Cookies.set("user", dt.data.username);
                                                        Cookies.set("status", dt.data.status);
                                                        Cookies.set("userId", dt.data.userId);
                                                        Cookies.set("companyId", dt.data.companyId);

                                                        const shareHost
                                                                    = window.baseHost === ""
                                                                        ? `${window.location.protocol}//${window.location.host}`
                                                                        : window.baseHost;

                                                        const link = `${shareHost}${window.baseAPI}` + `/index/ws/${localStorage.share_code}?p=${localStorage.share_p}&userId=${dt.data.userId}`;
                                                        window.open(link, "_self");

                                                        localStorage.removeItem("share_code");
                                                        localStorage.removeItem("share_p");
                                                    }
                                                    else {
                                                        if (window.root_platform) {
                                                            window.location.href = `${window.location.origin}/#/hub`;
                                                        }
                                                        else {
                                                            Cookies.set("access", dt.data.roleIds.toString());
                                                            Cookies.set("user", dt.data.username);
                                                            Cookies.set("status", dt.data.status);
                                                            Cookies.set("userId", dt.data.userId);
                                                            Cookies.set("companyId", dt.data.companyId);
                                                            // Cookies.set("companyId", 42);

                                                            // Required when company id is HARDCODED
                                                            Service.request("get", "/sys/user/info", "", (dt) => {
                                                                // Here to determine whether it's guest user and it has only one project so that we directly bring the guest into his project
                                                                Cookies.set("access", dt.data.roleIds.toString());

                                                                if (roleAccess.GUEST) {
                                                                    // It's a guest. Determine whether to redirect to project directly
                                                                    Service.request(
                                                                        "get",
                                                                        "/sys/project/page?page=1&limit=2&sidx=project_id&order=desc&title=&template=&creatorId=&start=&end=",
                                                                        undefined,
                                                                        (dt) => {
                                                                            const projectList = dt.page.list;

                                                                            if (projectList.length === 1) {
                                                                                // that.toProjectWorkspace(projectList[0]);
                                                                                const project = projectList[0];
                                                                                let redirectName;
                                                                                switch (project.template) {
                                                                                    case "smart_site":
                                                                                        redirectName = "smartsite_projectoverview";
                                                                                        break;
                                                                                    case "plan":
                                                                                        redirectName = "planviewer";
                                                                                        break;
                                                                                    case "ai":
                                                                                        redirectName = "aioverview";
                                                                                        break;
                                                                                    case "ai_detection":
                                                                                        redirectName = "teachable";
                                                                                        break;
                                                                                    case "sdms":
                                                                                    default:
                                                                                        redirectName = "projectoverview";
                                                                                }
                                                                                window.location.href = `${window.location.origin}/#/${redirectName}?prjid=${project.projectId}`;
                                                                                // router.push({
                                                                                //   name: redirectName,
                                                                                //   query: { prjid: project.projectId },
                                                                                // });
                                                                            }
                                                                            else {
                                                                                if (roleAccess.GUEST)
                                                                                    window.location.href = `${window.location.origin}/#/project/list`;
                                                                                // router.push({
                                                                                //   name: "project-list",
                                                                                // });
                                                                                else
                                                                                    window.location.href = `${window.location.origin}/#/storage/index`;
                                                                                // router.push({
                                                                                //   name: "storage",
                                                                                // });
                                                                            }
                                                                        },
                                                                    );
                                                                }
                                                                else {
                                                                    if (roleAccess.GUEST)
                                                                        window.location.href = `${window.location.origin}/#/project/list`;

                                                                    else
                                                                        window.location.href = `${window.location.origin}/#/storage/index`;
                                                                }
                                                            });
                                                        }
                                                    }
                                                }, (err) => {
                                                    console.error(err);
                                                });
                                            }, (err) => {
                                                console.error(err);
                                            });
                                        }
                                    }
                                });
                            })
                            .catch((error) => {
                                console.error("Error fetching user info:", error);
                            });
                    })
                    .catch((error) => {
                        console.error("Error exchanging code for token:", error);
                    });
            }
        },
    },
];

export default homeRoutes;
