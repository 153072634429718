<script>
/**
 *  全局上传插件，两种调用方式
 *   1. 作为全局页面的组件，使用event bus
 *   调用方法：Bus.$emit('openUploader', {params: {}, options: {}})
 *               params: 发送给服务器的额外参数；
 *               options：上传选项，目前支持 target、testChunks、mergeFn、accept
 *
 *   监听函数：Bus.$on('fileAdded', fn); 文件选择后的回调
 *           Bus.$on('fileSuccess', fn); 文件上传成功的回调，监听后记得释放
 *
 *   2. 作为普通组件在单个页面中调用，使用props
 */
import Cookies from 'js-cookie'
import SparkMD5 from 'spark-md5'
import Service from '../../libs/service'
import Bus from '../../libs/bus'
import { ACCEPT_CONFIG, mergeSimpleUpload } from '@/libs/uploadAccept'
import { forEach } from 'lodash'

export default {
  props: {
    global: {
      type: Boolean,
      default: true
    },
    // 发送给服务器的额外参数
    params: {
      type: Object
    },
    options: {
      type: Object
    }
  },

  data() {
    return {
      fileTitle: '',
      initOptions: {
        target: '/',
        headers: {
          token: '1332fa0bed8953694e26bfc7d5032241'
        },
        // chunkSize: '10485760',
        chunkSize: '5242880',
        fileParameterName: 'file',
        isDirectory: true,
        simultaneousUploads: 1,
        maxChunkRetries: 3,
        // 是否开启服务器分片校验
        testChunks: true,
        // 服务器分片校验函数，秒传及断点续传基础
        checkChunkUploadedByResponse(chunk, message) {
          let skip = false

          try {
            const objMessage = JSON.parse(message)
            if (objMessage.skipUpload) skip = true
            else skip = (objMessage.uploaded || []).includes(chunk.offset + 1)
          } catch (e) {
            console.warn(e)
          }

          return skip
        },
        testMethod: 'POST',
        query: file => {
          console.log(file, 'query??')
          return {
            ...file.params
          }
          // return {
          //   taskId: 95,
          //   md5: 'efd2b33e805ef3a487d6b48d0621c806'
          // }
        }
      },
      fileStatusText: {
        success: this.$t('x_upload_status_success'),
        error: this.$t('x_upload_status_fail'),
        uploading: this.$t('x_upload_status_uploading'),
        paused: this.$t('x_upload_status_paused'),
        waiting: this.$t('x_upload_status_waiting')
      },
      panelShow: false, // 选择文件后，展示上传panel
      collapse: false,
      customParams: {},
      checkParams: {},
      customStatus: '',
      initialChuckSize: 5242880,
      progressCount: 0, // 正在进行的任务数量
      modal: false,
      originFile: {}
    }
  },

  computed: {
    // Uploader实例
    uploader() {
      return this.$refs?.uploader?.uploader
    }
  },

  watch: {
    params: {
      handler(data) {
        if (data) this.customParams = data
      },
      immediate: true
    },
    options: {
      handler(data) {
        if (data) {
          setTimeout(() => {
            this.customizeOptions(data)
          }, 0)
        }
      },
      immediate: true
    }
  },

  mounted() {
    Bus.$on('openUploader', ({ params = {}, options = {}, checkParams = {} }) => {
      this.customParams = params
      this.checkParams = checkParams
      this.customizeOptions(options)
      console.log(this.uploader, 'uploader...')

      if (this.$refs.uploadBtn) this.$refs.uploadBtn.$el.click()
    })
  },

  methods: {
    openUploader({ params = {}, options = {}, checkParams = {} }) {
      // 由组件外部调用
      this.customParams = params
      this.checkParams = checkParams

      this.customizeOptions(options)
      console.log(this.uploader, 'uploader...')

      if (this.$refs.uploadBtn) this.$refs.uploadBtn.$el.click()
    },

    // 自定义options
    customizeOptions(opts) {
      if (Cookies.get('sdms_token'))
        this.uploader.opts.headers = { token: Cookies.get('sdms_token') }

      // 自定义上传url
      if (opts.target) {
        console.log(opts.target, 'target')
        this.uploader.opts.target = opts.target
      }

      // 是否可以秒传、断点续传
      if (opts.testChunks !== undefined) this.uploader.opts.testChunks = opts.testChunks

      if (opts.chuckSize) this.uploader.opts.chunkSize = opts.chuckSize

      // if (opts.isDirectory) {
      //   this.uploader.
      // }

      // merge 的方法，类型为Function，返回Promise
      this.mergeFn = opts.mergeFn || mergeSimpleUpload

      // 自定义文件上传类型
      this.$nextTick(() => {
        const input = document.querySelector('#global-uploader-btn input')
        const accept = opts.accept || ACCEPT_CONFIG.getAll()
        console.log(input, 'inputDOM')
        input.setAttribute('accept', accept.join())
      })
    },

    async onFileAdded(files, fileList) {
      this.emit('uploadPoptipClick', true) // 调用父组件Poptip的click事件, 展开文件列表
      this.panelShow = true
      this.emit('fileAdded')
      //   定义一个数组把需要两遍check的文件存起来
      let fileArr = []
      for (const file of fileList) {
        this.originFile = _.assign(file, _.cloneDeep(file))
        // 将额外的参数赋值到每个文件上，以不同文件使用不同params的需求
        file.params = this.customParams
        // 计算MD5
        const result = await this.computeMD5(file)
        if (result?.data?.fileStatus === 1) {
          fileArr.push(file)
        }
        //   this.modal = true
        else {
          this.startUpload(result)
        }
      }
      //   调用两遍check文件函数
      this.uploadDoubleCheckFiles(fileArr)
    },
    // 是否覆盖的文件数组，进行循环定义
    async uploadDoubleCheckFiles(fileArr) {
      for (const singleFile of fileArr) {
        this.originFile = _.assign(singleFile, _.cloneDeep(singleFile))
        this.modal = true
        this.fileTitle = this.originFile.name
        // 等待用户处理 modal 框
        await this.waitForModalToClose()
      }
    },
    /**
     * 计算md5值，以实现断点续传及秒传
     * @param file
     * @returns Promise
     */
    computeMD5(file, action) {
      console.log(file, '计算前的file')
      const _this = this
      const fileReader = new FileReader()
      const time = new Date().getTime()
      const blobSlice =
        File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let currentChunk = 0
      let md5Status = 'progress'
      const fileSize = file.size
      // 最大文件大小为500GB
      if (fileSize > 500 * 1024 * 1024 * 1024) {
        file.cancel()
        this.error(`${this.$t('x_file_exceeds_upload_limit')}: 500GB`)
        this.emit('uploadPoptipClick', false)
        return
      }
      // 最小切片大小为5MB,
      const minChuckSize = 5 * 1024 * 1024 // 5 * 1024 * 1024
      let chunkSize
      if (_.divide(fileSize, 10000) < minChuckSize) {
        chunkSize = minChuckSize
        this.uploader.opts.chunkSize = chunkSize
        file.bootstrap()
      } else {
        chunkSize = _.divide(fileSize, 10000)
        const megaByteCeil = _.ceil(chunkSize / 1024 / 1024) // 每个切片的大小, 在MB级向上取整
        chunkSize = megaByteCeil * 1024 * 1024
        this.initOptions.chunkSize = chunkSize
        this.uploader.opts.chunkSize = chunkSize
        file.bootstrap()
      }

      let chunks = Math.floor(fileSize / chunkSize)
      chunks = chunks || 1

      const spark = new SparkMD5.ArrayBuffer()
      // let type = file?.name?.slice(file.name.lastIndexOf('.') + 1)

      // 文件状态设为"计算MD5"
      if (action === undefined) this.statusSet(file.id, 'md5')

      file.pause()

      loadNext()

      return new Promise((resolve, reject) => {
        fileReader.onload = e => {
          spark.append(e.target.result)

          if (md5Status === 'progress') {
            // currentChunk++;
            currentChunk = chunks - 1
            loadNext()
            md5Status = 'complete'

            // 实时展示MD5的计算进度
            // this.$nextTick(() => {
            //   const md5ProgressText =
            //     "校验MD5 " + ((currentChunk / chunks) * 100).toFixed(0) + "%";
            //   document.querySelector(`.custom-status-${file.id}`).innerText =
            //     md5ProgressText;
            // });
          } else {
            const md5 = spark.end()

            let params = {
              chunks,
              filename: file.name,
              md5,
              type: file.fileType,
              uploadMode: action ? (action === 'cover' ? 0 : 1) : undefined
            }
            params = _.assign(params, _.cloneDeep(_this.checkParams))
            Service.request(
              'post',
              '/oss/check',
              params,
              dt => {
                console.log(dt.data.filename)
                // _this.queryFileList(_this.currentParentId);
                _this.newFolderModal = false
                // if (this.customParams.storageItem !== null) {

                // }
                file.params = {
                  ...file.params,
                  taskId: dt.data.taskId,
                  filename: dt?.data?.filename
                }

                if (dt.data) {
                  if (dt?.data?.flag) {
                    _this.statusSet(file.id, 'immediateSuccess')
                    _this.emit('fileSuccess')
                    reject()
                  } else {
                    // md5计算完毕
                    resolve({ md5, file, data: dt.data })
                  }
                }
                // 判断文件名是否是中文
                // if (!regex1.test(dt.data.filename)) {
                // file.params = {
                //   ...file.params,
                //   taskId: dt.data.taskId,
                // };

                // if (dt.data) {
                //   if (dt?.data?.flag) {
                //     _this.statusSet(file.id, "immediateSuccess");
                //     _this.emit("fileSuccess");
                //     reject();
                //   } else {
                //     // md5计算完毕
                //     resolve({ md5, file, data: dt.data });
                //   }
                // }
                // } else {
                //   _this.error(`“${dt.data.filename}”文件名存在中文，请修改该文件名再上传`)
                //   file.cancel();
                // }
              },
              err => {
                console.error(err)
                reject()
              }
            )
            console.log(
              `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${
                new Date().getTime() - time
              } ms`
            )
          }
        }

        fileReader.onerror = function () {
          _this.error(_this.$t('x_upload_read_file_error'))
          file.cancel()
          reject()
        }
      })

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = start + chunkSize >= file.size ? file.size : start + chunkSize

        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
      }
    },

    // md5计算完毕，开始上传
    startUpload({ md5, file, data }) {
      const arr = []
      file.chunks.map(i => {
        arr.push(i)
      })

      this.initOptions.query = {
        storageItem: data.storageItem,
        taskId: data.taskId
      }
      file.uniqueIdentifier = md5
      // 调俩边check分片会双倍，实在不知道怎么改逻辑，所以在这里写这个奇奇怪怪的处理。
      if (data?.uploadMode === 1 || data?.uploadMode === 0)
        file.chunks = arr.slice(0, arr.length / 2)
      this.progressCountChange(1)

      // return;
      file.resume()
      this.statusRemove(file.id)
    },

    onFileSuccess(rootFile, file, response) {
      const res = JSON.parse(response)
      // 服务端自定义的错误（即http状态码为200，但是是错误的情况），这种错误是Uploader无法拦截的
      if (!res.data.flag) {
        this.error(res.msg)
        // 文件状态设为“失败”
        this.statusSet(file.id, 'failed')
        return
      }

      // 如果服务端返回了需要合并的参数
      if (res.needMerge) {
        // 文件状态设为“合并中”
        this.statusSet(file.id, 'merging')

        this.mergeFn({
          tempName: res.tempName,
          fileName: file.name,
          ...file.params
        })
          .then(() => {
            // 文件合并成功
            this.emit('fileSuccess')
            this.statusRemove(file.id)
            this.progressCountChange(-1)
          })
          .catch(() => {})

        // 不需要合并
      } else {
        console.log('res.data', res.data)
        this.emit('fileSuccess')
        console.log('上传成功')
        this.progressCountChange(-1)
      }
    },

    onFileProgress(rootFile, file, chunk) {
      console.log(
        `上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${
          chunk.endByte / 1024 / 1024
        }`
      )
    },

    onFileError(rootFile, file, response) {
      this.error(response)
    },

    onFileRemoved(file) {
      console.log(file, 'onFileRemoved')
      this.progressCountChange(-1)
    },

    close() {
      this.uploader.cancel()

      this.panelShow = false
    },

    /**
     * 新增的自定义的状态: 'md5'、'merging'、'transcoding'、'failed'
     * @param id
     * @param status
     */
    statusSet(id, status) {
      const statusMap = {
        md5: {
          text: this.$t('x_upload_md5_checking'),
          bgc: '#fff'
        },
        merging: {
          text: this.$t('x_upload_file_merging'),
          bgc: '#99c9c4'
        },
        transcoding: {
          text: this.$t('x_upload_file_transcoding'),
          bgc: '#99c9c4'
        },
        failed: {
          text: this.$t('x_upload_status_fail'),
          bgc: '#99c9c4'
        },
        immediateSuccess: {
          text: this.$t('x_upload_status_success'),
          bgc: '#fff'
        }
      }

      this.customStatus = status
      this.$nextTick(() => {
        const statusTag = document.createElement('p')
        statusTag.className = `custom-status-${id} custom-status`
        statusTag.innerText = statusMap[status].text
        statusTag.style.backgroundColor = statusMap[status].bgc

        const statusWrap = document.querySelector(`.file_${id} .uploader-file-status`)
        console.log(statusWrap, 'statusWrap')
        statusWrap.appendChild(statusTag)
        if (status === 'immediateSuccess') {
          const nodes = document.querySelector(`.custom-status-${id}`).parentNode.parentNode
            .lastChild.children
          const length = nodes.length
          for (let i = 0; i < length - 1; i++) nodes && nodes[0] && nodes[0]?.remove()

          this.$forceUpdate()
        }
      })
    },

    statusRemove(id) {
      this.customStatus = ''
      this.$nextTick(() => {
        const statusTag = document.querySelector(`.custom-status-${id}`)
        statusTag?.remove()
      })
    },

    emit(e) {
      Bus.$emit(e)
      this.$emit(e)
    },

    error(msg) {
      this.$Message.error({
        content: msg || this.$t('x_common_error'),
        duration: 5
      })
    },

    progressCountChange(num) {
      if (this.progressCount + num > -1) {
        this.progressCount += num
        this.emit('progressCountChange', this.progressCount)
      }
    },

    confirm(action) {
      // 将额外的参数赋值到每个文件上，以不同文件使用不同params的需求
      this.originFile.params = this.customParams
      this.computeMD5(this.originFile, action)?.then(result => {
        this.emit('uploadPoptipClick', true) // 调用父组件Poptip的click事件, 展开文件列表
        this.panelShow = true
        this.emit('fileAdded')
        this.startUpload(result)
      })
      this.modal = false
    },
    // 等待弹窗状态是否发生改变
    waitForModalToClose() {
      return new Promise(resolve => {
        const checkIfClosed = () => {
          if (!this.modal) {
            resolve()
          } else {
            requestAnimationFrame(checkIfClosed)
          }
        }
        checkIfClosed()
      })
    }
  }
}
</script>

<template>
  <div id="global-uploader" :class="{ 'global-uploader-single': !global }">
    <!-- 上传 -->
    <uploader
      ref="uploader"
      :options="initOptions"
      :file-status-text="fileStatusText"
      :auto-start="false"
      class="uploader-app"
      webkitdirectory="true"
      @files-added="onFileAdded"
      @file-success="onFileSuccess"
      @file-progress="onFileProgress"
      @file-error="onFileError"
      @file-removed="onFileRemoved"
    >
      <uploader-unsupport />

      <uploader-btn id="global-uploader-btn" ref="uploadBtn">
        {{ $t('x_upload_choose_file') }}
      </uploader-btn>

      <uploader-list v-show="panelShow">
        <template #default="props">
          <div class="file-panel" :class="{ collapse }">
            <div class="file-title">
              <div class="title">
                {{ $t('x_upload_file_upload') }}
              </div>
              <div class="operate">
                <Button v-if="false" type="text" :title="$t('x_close')" @click="close">
                  <i class="ivu-icon ivu-icon-md-close" />
                </Button>
              </div>
            </div>

            <ul class="file-list">
              <li v-for="file in props.fileList" :key="file.id" class="file-item">
                <uploader-file
                  ref="files"
                  :class="[`file_${file.id}`, customStatus]"
                  :file="file"
                  :list="true"
                />
              </li>
              <div v-if="!props.fileList.length" class="no-file">
                <i class="iconfont icon-empty-file" />
                {{ $t('x_nodata') }}
              </div>
            </ul>
          </div>
        </template>
      </uploader-list>
    </uploader>
    <Modal v-model="modal" :mask-closable="false" :title="fileTitle">
      <div>{{ $t('x_upload_info') }}</div>

      <template #footer>
        <div>
          <!--  -->
          <Button type="primary" @click="confirm('cover')">
            {{ $t('x_cover') }}
          </Button>
          <Button type="primary" @click="confirm('createCopy')">
            {{ $t('x_create_copy') }}
          </Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="less">
@import './GlobalUploader.less';
</style>
