import authRoutes from "@/router/routes/auth.js";
import homeRoutes from "@/router/routes/home.js";
import projectRoutes from "@/router/routes/project.js";
import storageRoutes from "@/router/routes/storage.js";
import accountRoutes from "@/router/routes/account.js";
import Menu from "@/views/menu/index.vue";
import assetRoutes from "@/router/routes/asset.js";
import SubMenu from "@/views/menu/subIndex.vue";
import projectDetailRoutes from "@/router/routes/projectDetails.js";
import shareRoutes from "@/router/routes/share.js";

const routes = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/menu",
        name: "menu",
        component: Menu,
        children: [
            ...storageRoutes,
            ...accountRoutes,
            ...projectRoutes,
            ...assetRoutes,
        ],
    },
    {
        path: "/subMenu",
        name: "subMenu",
        component: SubMenu,
        children: [
            ...projectDetailRoutes,
        ],
    },
    {
        path: "/error",
        name: "error",
        component: () => import("@/pages/Error/GtError.vue"),
    },
    ...homeRoutes,
    ...authRoutes,
    ...shareRoutes,
];

export default routes;
