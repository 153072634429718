import Cookies from "js-cookie";

function isLogin() {
    const sdms_token = Cookies.get("sdms_token");
    const userId = Cookies.get("userId");

    return !!sdms_token && !!userId;
}

export {
    isLogin,
};
