/**
 * @author: YongCode
 * @date: 2020-08-05 12:59
 */

// 用例
// <template>
//   <div v-for="(item,index) in imgSrc" :key="index" >
//     <img v-imgLazy="item" >
//   </div>
// </template>
//
// data() {
//   return {
//     imgSrc: ['https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1091405991,859863778&fm=26&gp=0.jpg']
//   }
// }
// 需要给图片设置宽高

// 引入默认图片
import baseImg from "/public/images/baseImg.png";

let timer = null;

const options = {
    rootMargin: "0px 0px 300px 0px",
};

// 创建一个监听器
const observer = new IntersectionObserver((entries) => {
    // entries是所有被监听对象的集合
    entries.forEach((entry) => {
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
            // 当被监听元素到临界值且未加载图片时触发。
            !entry.target.isLoaded && showImage(entry.target, entry.target.data_src);
        }
    });
}, options);

function showImage(el, imgSrc) {
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
        el.src = imgSrc;
        el.isLoaded = true;
    };
}

export default {
    // 这里用inserted和bind都行，因为IntersectionObserver时异步的，以防意外还是用inserted好一点
    // inserted和bind的区别在于inserted时元素已经插入页面，能够直接获取到dom元素的位置信息。
    inserted(el, binding, vnode) {
        clearTimeout(timer);
        // 初始化时展示默认图片
        el.src = baseImg;
        // 将需要加载的图片地址绑定在dom上
        el.data_src = binding.value;
        observer.observe(el);

        // 防抖, 这里在组件卸载的时候停止监听
        const vm = vnode.context;
        timer = setTimeout(() => {
            vm.$on("hook:beforeDestroy", () => {
                observer.disconnect();
            });
        }, 20);
    },
    // 图片更新触发(当v-imgLazy指令绑定的url发生变化时)
    update(el, binding) {
        el.isLoaded = false;
        el.data_src = binding.value;
    },
};
