<script setup>
import { ref } from "vue";
import useFormValidator from "@/hooks/useFormValidator.js";
import Service from "@/libs/service.js";

const emits = defineEmits(["to-login"]);

const { validateEmail } = useFormValidator();

const isShowSubmitSuccess = ref(false);
const submitSuccessTip = ref("");

const form_ref = ref(null);

const formData = ref({
    email: "",
});

const rules = {
    email: [
        { validator: validateEmail, trigger: "blur" },
    ],
};

const loading = ref(false);

function submit() {
    form_ref.value.validate((valid) => {
        if (!valid)
            return;
        loading.value = true;
        Service.request(
            "put",
            "/sys/user/forget",
            {
                email: formData.value.email,
            },
            (data) => {
                submitSuccessTip.value = data.msg;
                loading.value = false;
                isShowSubmitSuccess.value = true;
            },
            () => {
                loading.value = false;
            },
        );
    });
}

function goBackToLogin() {
    emits("to-login");
    isShowSubmitSuccess.value = false;
}
</script>

<template>
    <div style="position: relative">
        <div v-if="!isShowSubmitSuccess">
            <div class="forget-pwd-header">
                <h1>
                    {{ $t("x_forget_pwd") }}
                </h1>
            </div>
            <div class="forget-pwd-tip">
                {{ $t("x_forget_pwd_tip") }}
            </div>

            <i-form ref="form_ref" :model="formData" :rules="rules">
                <FormItem prop="email" :label="$t('x_email')">
                    <Input v-model="formData.email" size="large" :placeholder="$t('x_email')" />
                </FormItem>
                <FormItem>
                    <Button type="primary" size="large" long style="margin-top: 1rem" @click="submit">
                        {{ $t("x_submit") }}
                    </Button>
                </FormItem>
            </i-form>
            <Row type="flex" justify="center" style="margin-top: 1rem">
                <p>
                    <a @click.prevent="goBackToLogin">{{ $t("x_back_to_login") }}</a>
                </p>
            </Row>
            <Spin v-if="loading" />
        </div>
        <div v-else>
            <div class="forget-pwd-header">
                <h1>
                    {{ $t("x_submit_success") }}
                </h1>
            </div>
            <div class="forget-pwd-tip">
                {{ submitSuccessTip }}
            </div>
            <Row type="flex" justify="center" style="margin-top: 1.2rem">
                <a @click.prevent="goBackToLogin">{{ $t("x_back_to_login") }}</a>
            </Row>
        </div>
    </div>
</template>

<style scoped lang="less">
.forget-pwd-header {
    margin-bottom: 20px;
}
.forget-pwd-tip {
    margin: 1rem 0 1.5rem 0;
}
</style>
