<script>
import { ref, watchEffect } from "vue";
import { useElementSize } from "@vueuse/core";
import AppContent from "@/views/appContent/AppContent.vue";

export default {
    components: { AppContent },
    setup() {
        const leftMenu = ref(null);
        const { width } = useElementSize(leftMenu);

        const showMenuTitle = ref(true);

        watchEffect(() => {
            showMenuTitle.value = width.value >= 1000;
        });

        return {
            leftMenu,
            showMenuTitle,
        };
    },
    data() {
        return {
            menuList: [],
            routerName: "",
            tabsActive: "",
            showMenuTitle: true,
            currDropMenu: "",
        };
    },
    computed: {
        // tabsList() {
        //     const tabsList = this.$store.getters.getTabsList;
        //     return tabsList;
        // },
        switchMenu() {
            return this.$store.getters.getSwitchMenu;
        },
        activeMenuName() {
            return this.$store.getters.getActiveMenuName;
        },
    },
    watch: {
        $route(to) {
            this.routerName = to.name;
        },
    },
    mounted() {
        this.menuList = this.$store.getters.getProjectCommonMenuList;
        const query = JSON.parse(localStorage.getItem("query"));
        const preifx = query.template || "common";
        switch (preifx) {
            case "common":
                this.menuList = this.$store.getters.getProjectCommonMenuList;
                break;
            case "smart":
                this.menuList = this.$store.getters.getProjectSmartConstructionMenuList;
                break;
            case "ai":
                this.menuList = this.$store.getters.getProjectAIMenuList;
                break;
            default:
                this.menuList = this.$store.getters.getProjectCommonMenuList;
                break;
        }
        // this.activeMenuName = this.$route.path;
        this.$store.commit("setActiveMenuName", this.$route.path);
    },
    methods: {
        getRefreshMenu(type) {
            const projectMenuList = this.$store.getters.getProjectMenuList;
            const dataMenuList = this.$store.getters.getDataMenuList;
            const assetMenuList = this.$store.getters.getAssetMenuList;
            const accountMenuList = this.$store.getters.getAccountMenuList;
            if (type === "project") {
                this.menuList = projectMenuList;
                this.$nextTick(() => {
                    this.$store.commit("setActiveMenuName", projectMenuList[1].path);
                    this.toPath(projectMenuList[0].path);
                    this.tabsActive = projectMenuList[0].title;
                });
                this.$store.commit("addTabsList", projectMenuList[1]);
            }
            else if (type === "data") {
                this.menuList = dataMenuList;
                this.$nextTick(() => {
                    this.$store.commit("setActiveMenuName", dataMenuList[0].path);
                    this.toPath(dataMenuList[0].path);
                    this.tabsActive = dataMenuList[0].title;
                });
                this.$store.commit("addTabsList", dataMenuList[0]);
            }
            else if (type === "asset") {
                this.menuList = assetMenuList;
                this.$nextTick(() => {
                    this.$store.commit("setActiveMenuName", assetMenuList[0].path);
                    this.toPath(assetMenuList[0].path);
                    this.tabsActive = assetMenuList[0].title;
                });
                this.$store.commit("addTabsList", assetMenuList[0]);
            }
            else if (type === "account") {
                this.menuList = accountMenuList;
                // this.$nextTick(() => {
                //     this.$store.commit("setActiveMenuName", accountMenuList[0].path);
                //     this.toPath(accountMenuList[0].path);
                //     this.tabsActive = accountMenuList[0].title;
                // });
                this.$nextTick(() => {
                    this.$store.commit("setActiveMenuName", "/account/profile");
                    this.toPath("/account/profile");
                    const subMenu = accountMenuList.find(item => item.path === "/account/profile");
                    this.tabsActive = subMenu.title;
                });
                this.$store.commit("addTabsList", accountMenuList[0]);
            }
        },
        toPath(item) {
            this.$router.push({
                path: item,
            });
        },
        toChangeMenuName(val) {
            console.log(val);
            const allMenuList = this.$store.getters.getAllTemplateMenu;
            let currentMenu = {};
            if (JSON.stringify(allMenuList).includes(val)) {
                allMenuList.map((value) => {
                    if (!value.children) {
                        if (val === value.path)
                            currentMenu = value;
                        return currentMenu;
                    }
                    else {
                        value.children.map((k) => {
                            if (val === k.path)
                                currentMenu = k;
                            return currentMenu;
                        });
                    }
                });
            }
            if (!currentMenu.path)
                return;
            this.$store.commit("addTabsList", currentMenu);
            this.tabsActive = currentMenu.title;
            const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
            const params = new URLSearchParams(val.split("?")[1]);
            if (params.get("url"))
                query.url = params.get("url");// 改变参数
            this.$router.push({ path: currentMenu.path, query }); // 更新路由
            // 原本的代码，导致项目里的iframe 嵌套url参数被query中的url覆盖了， 我把query打出来都是一样的。
            // this.$router.push({
            //     path: currentMenu.path,
            //     query: this.$route.query,
            // });
            // setTimeout(() => { winTitle.document.title = `${row.title}${this.$t("x_overview_project")}- GeoTwin`; }, 100);
        },
        openNewTab() {
            const routeData = this.$router.resolve(to);
            const winTitle = window.open(routeData.href, "_blank");
            setTimeout(() => { winTitle.document.title = `${row.title}`; }, 100);
        },
        toChangeMenuTabs(val) {
            const allMenuList = this.$store.getters.getAllMenu;
            let currentTab = {};
            if (JSON.stringify(allMenuList).includes(val)) {
                allMenuList.map((value) => {
                    if (val === value.title)
                        currentTab = value;
                    return currentTab;
                });
            }
            this.tabsActive = currentTab.title;
            this.$router.push({
                path: currentTab.path,
            });
        },
        handleTabRemove(name) {
            this[`tab${name}`] = false;
            this.$store.commit("delTabsList", name);
        },
    },
};
</script>

<template>
    <div id="leftMenu">
        <div :class="showMenuTitle ? 'left-menu' : 'left-menu-hide'">
            <div style="flex-grow: 1" class="menu-content">
                <Menu :active-name="activeMenuName" @on-select="toChangeMenuName">
                    <template v-for="(item, index) in menuList">
                        <MenuItem v-if="!item.children || (item.children && !showMenuTitle)" :key="index" :name="item.path">
                            <template v-if="showMenuTitle">
                                <i :class="item.icon" />
                                <span>{{ $t(item.title) }}</span>
                            </template>
                            <Tooltip v-if="!item.children && !showMenuTitle" :content="$t(item.title)" placement="right">
                                <i :class="item.icon" />
                            </Tooltip>
                            <div v-if="item.children && !showMenuTitle" class="drop-wrapper">
                                <Dropdown
                                    placement="right-start"
                                    @on-click="toChangeMenuName"
                                >
                                    <i :class="item.icon" />
                                    <template #list>
                                        <DropdownMenu>
                                            <DropdownItem
                                                v-for="(u, x) in item.children"
                                                :key="x"
                                                :name="u.path"
                                                :selected="u.path === currDropMenu"
                                                placement="right-start"
                                            >
                                                {{ $t(u.title) }}
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </template>
                                </Dropdown>
                            </div>
                        </MenuItem>
                        <Submenu v-if="item.children && showMenuTitle" :key="index" :name="item.path">
                            <template #title>
                                <template>
                                    <i :class="item.icon" />
                                    <span>{{ $t(item.title) }}</span>
                                </template>
                            </template>
                            <MenuItem v-for="(k, j) in item.children" :key="j" :name="k.path">
                                <i :class="k.icon" />
                                <span>{{ $t(k.title) }}</span>
                            </MenuItem>
                        </Submenu>
                    </template>
                </Menu>
            </div>

            <div class="footer">
                <div class="menu-fold-icon" @click="showMenuTitle = !showMenuTitle">
                    <i v-show="showMenuTitle" class="icon iconfont icon-menu-fold" />
                    <i v-show="!showMenuTitle" class="icon iconfont icon-menu-unfold" />
                    <span v-show="showMenuTitle">
                        {{ $t("x_fold_menu") }}
                    </span>
                </div>
            </div>
        </div>
        <AppContent>
            <router-view />
        </AppContent>
    </div>
</template>

<style lang="less" scoped>
#leftMenu{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: @gt-bg-color-1;
    .left-menu{
        flex-shrink: 0;
        width: 224px;
        height: 100%;
        border-right: 1px solid @gt-border-color-1;
        display: flex;
        flex-direction: column;
    }
    .left-menu-hide{
        flex-shrink: 0;
        width: 70px;
        height: 100%;
        border-right: 1px #E8EAEC solid;
        display: flex;
        flex-direction: column;
    }
    .ivu-menu{
        width: 100% !important;
    }
    .ivu-menu-light:after{
        background-color: #E8EAEC !important;
        width: 0 !important;
    }
    .ivu-menu-item-selected, .ivu-dropdown-item-selected {
        background-color: @gt-bg-color-2 !important;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu) {
        border: none !important;
        color: @gt-text-color-5 !important;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu):after{
        width:0 !important;
        background-color: red !important;
        color: @gt-text-color-5 !important;
    }
}

.footer {
    flex-shrink: 0;
    margin: 0 @gt-page-padding-1 @gt-space-2 @gt-page-padding-1;
    height: 2rem;
    border-top: 1px solid @gt-border-color-1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .menu-fold-icon {
        cursor: pointer;

        &:hover {
            color: @gt-brand-6;
        }
    }
}
</style>
