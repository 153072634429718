<script setup>
import { computed } from "vue";
import LanguageSelecter from "@/components/LanguageSelecter.vue";
import PageHeader from "@/components/PageHeader.vue";
import store from "@/store/index.js";

const deviceType = computed(() => {
    return store.state.deviceType;
});
</script>

<template>
    <PageHeader :title="$t('x_app_title')">
        <template #extra>
            <LanguageSelecter />
        </template>
    </PageHeader>
</template>

<style scoped lang="less">

</style>
