<script setup>
import useLanguage from "@/hooks/useLanguage.js";

const { language, setLanguage } = useLanguage();

const languageMap = {
    "en-US": "English",
    "zh-CN": "简体中文",
    "zh-TW": "繁體中文",
};
</script>

<template>
    <Dropdown trigger="click" @on-click="setLanguage">
        <div style="cursor: pointer;">
            {{ languageMap[language] }}
            <i class="fal fa-angle-down" />
        </div>
        <template #list>
            <DropdownMenu>
                <DropdownItem name="en-US">
                    <p :class="language === 'en-US' ? 'item-active' : 'item'">
                        English
                    </p>
                </DropdownItem>
                <DropdownItem name="zh-CN">
                    <p :class="language === 'zh-CN' ? 'item-active' : 'item'">
                        简体中文
                    </p>
                </DropdownItem>
                <DropdownItem name="zh-TW">
                    <p :class="language === 'zh-TW' ? 'item-active' : 'item'">
                        繁體中文
                    </p>
                </DropdownItem>
            </DropdownMenu>
        </template>
    </Dropdown>
</template>

<style scoped lang="less">
.item {
    color: @gt-text-color-4;
}
.item-active {
    color: @gt-brand-6;
}
</style>
