import { computed, getCurrentInstance } from "vue";
import store from "@/store/index.js";

export default function useLanguage() {
    const el = getCurrentInstance();

    const language = computed(() => {
        return store.state.user.language;
    });

    function setLanguage(language) {
        store.commit("user/setLanguage", language);
        el.proxy.$i18n.locale = language;
        // el.proxy.$addStorageEvent(1, "language", language);
    }

    function changeLanguage(language) {
        return store.dispatch("user/changeLanguage", language).then(() => {
            el.proxy.$i18n.locale = language;
            // window.systemVue.$i18n.locale = language;
            // el.proxy.$addStorageEvent(1, "language", language);
        });
    }

    return {
        language,
        setLanguage,
        changeLanguage,
    };
}
